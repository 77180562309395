import React from "react"
import { usePageContext, useTranslation } from "@3nvi/gatsby-theme-intl"
import { StaticImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"

import Layout from "../components/layout"

const ForkArms = () => {
  const { t } = useTranslation()
  const { lang } = usePageContext()
  return (
    <Layout>
      <div class="flex flex-row justify-around">
        <GatsbyLink to={`/${lang}/standard`} className="w-auto border border-grey">
          <StaticImage src="../images/fa-standard.jpeg" alt="Standard" />
          <div class="w-full bg-red text-white text-center text-xl">{t("fork-arms.first")}</div>
        </GatsbyLink>
        <GatsbyLink to={`/${lang}/big-forks`} className="w-auto border border-grey">
          <StaticImage src="../images/fa-big-forks.jpeg" alt="Big Forks" />
          <div class="w-full bg-red text-white text-center text-xl">{t("fork-arms.second")}</div>
        </GatsbyLink>
        <GatsbyLink to={`/${lang}/${t("home.third-link")}`} className="w-auto border border-grey">
          <img src={t("fork-arms.third-image")} alt="Custom Build / Accessories"/>
          <div class="w-full bg-red text-white text-center text-xl">{t("fork-arms.third")}</div>
        </GatsbyLink>
        <GatsbyLink to={`/${lang}/construction`} className="w-auto border border-grey">
          <StaticImage src="../images/fa-construction.jpeg" alt="Construction" />
          <div class="w-full bg-red text-white text-center text-xl">{t("fork-arms.fourth")}</div>
        </GatsbyLink>
      </div>
    </Layout>
  )
}

export default ForkArms


